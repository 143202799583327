import React from 'react';
import AssistantSpeechIndicator from "./call/AssistantSpeechIndicator";
import Button from "./base/Button";
// import VolumeLevel from "./call/VolumeLevel";
import SoundWave from "./SoundWave";

const ActiveCallDetail = ({ assistantIsSpeaking, volumeLevel, onEndCallClick, isMuted, onUserSpeak, onUserMicMute }) => {
  return (
    <div>
      <div className='opacity-animation-waves'
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          border: "1px solid #ddd",
          borderRadius: "20px",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
          background:"rgba(0,0,0,0.7)",
        }}
      >
       
        <AssistantSpeechIndicator isSpeaking={assistantIsSpeaking} />
        <SoundWave isSpeaking={assistantIsSpeaking} />
        {/* <VolumeLevel volume={volumeLevel} /> */}
      </div>
      
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Button label="End" onClick={onEndCallClick} onUserSpeak={onUserSpeak} onUserMicMute={onUserMicMute} assistantIsSpeaking={true} isMuted={isMuted} />
      </div>
     
    </div>
  );
};

export default ActiveCallDetail;
